import React from 'react'
// import { Link, graphql, useStaticQuery } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

// import './header.module.scss'
import headerStyles from './header.module.scss'

const Header = () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
            contentfulPageHeader {
                mainLogo {
                    file {
                        url
                    }
                    title
                }
                mobileLogo {
                    file {
                        url
                    }
                    title
                }
                headerImage {
                    file {
                        url
                    }
                    title
                }
                logoText {
                    json 
                }
                headerContent {
                    json
                }
            }
        }
    `)

    return (
        <header className={ headerStyles.banner } style={{backgroundImage: `url(${data.contentfulPageHeader.headerImage.file.url})`}}>
            {/* <h1>
                <Link 
                    className={ headerStyles.title } 
                    to="/">
                        { data.site.siteMetadata.title }
                </Link>
            </h1> */}
            <div className={ headerStyles.brandContainer }>
                <img src={ data.contentfulPageHeader.mainLogo.file.url } alt={ data.contentfulPageHeader.mainLogo.title } />
                <div className={headerStyles.tagLine}>
                 { documentToReactComponents(data.contentfulPageHeader.logoText.json) }
                </div>
            </div>
            {/* <nav>
                <ul className={ headerStyles.navList }>
                    <li>
                        <Link 
                        className={ headerStyles.navItem } 
                        activeClassName={ headerStyles.activeNavItem } 
                        to="/">
                            Home
                        </Link>
                    </li>
                    <li>
                        <Link 
                        className={ headerStyles.navItem } 
                        activeClassName={ headerStyles.activeNavItem } 
                        to="/about">
                            About
                        </Link>
                    </li>
                    <li>
                        <Link 
                        className={ headerStyles.navItem } 
                        activeClassName={ headerStyles.activeNavItem } 
                        to="/contact">
                            Contact
                        </Link>
                    </li>
                    <li>
                        <Link 
                        className={ headerStyles.navItem } 
                        activeClassName={ headerStyles.activeNavItem } 
                        to="/blog">
                            Blog
                        </Link>
                    </li>
                </ul>
            </nav> */}
            <div className={ headerStyles.headerContent }>
                { documentToReactComponents(data.contentfulPageHeader.headerContent.json) }
            </div>
        </header>
    )
}

export default Header